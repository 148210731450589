.full-viewport-height {
  min-height: 100vh;
}

.grv-button--text-directional-left {
  &,
  &:hover {
    color: var(--grv-color-white);
  }

  &::before,
  &:hover::before {
    border-color: var(--grv-color-white);
  }
}

.display-none {
  display: none;
}
