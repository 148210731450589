@import "@cof/omni-gravity-tokens/lib/index.css"; // Use for colors
@import "@cof/omni-tokens/index.css"; // Use for fonts and anything gravity-tokens does not have
@import "./styles/global";

html,
body {
  margin: auto;
  background-color: var(--grv-color-blue85);
}
.asterisk {
  font-weight: var(--omni-fontWeight-600-semiBold);
  color: var(--grv-color-textCritical);
}

.modal-dialog {
  border-radius: 5px !important;
}

.Toastify__toast-container {
  /* prettier-ignore */
  width: 33% !important;
  cursor: pointer;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.Toastify__toast-body {
  color: var(--grv-color-textNeutralPrimaryHover);
}

.Toastify__toast-theme--light.Toastify__toast--success {
  background: var(--grv-color-objectFavorableEmphasisLowHover);
  box-shadow: inset 0px 4px 0px
    var(--grv-color-objectFavorableEmphasisHighHover);

  .Toastify__toast-icon {
    color: var(--grv-color-objectFavorableEmphasisHighHover);
  }
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background: var(--grv-color-objectCriticalEmphasisLowHover);
  box-shadow: inset 0px 4px 0px var(--grv-color-objectCriticalEmphasisHighHover);

  .Toastify__toast-icon {
    color: var(--grv-color-objectCriticalEmphasisHighHover);
  }
}

.Toastify__toast-theme--light.Toastify__toast--warning {
  background: var(--grv-color-yellow10);
  box-shadow: inset 0px 4px 0px var(--grv-color-textWarning);

  .Toastify__toast-icon {
    color: var(--grv-color-textWarningOnDark);
  }
}

.Toastify__toast-theme--light.Toastify__toast--info {
  background: var(--grv-color-blue10);
  box-shadow: inset 0px 4px 0px var(--grv-color-blue80);

  .Toastify__toast-icon {
    color: var(--grv-color-blue80);
  }
}

// On desktop viewports, the nav and main container should scroll individually.
// On mobile, the body is the only scroll container.
// So we are hiding the overflow on desktop to prevent an unnecessary scrollbar
@media only screen and (min-width: 1024px) {
  body {
    overflow: hidden;
  }
}
