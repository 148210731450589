.suspense-fallback-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app-root {
  background-color: transparent !important;
}
